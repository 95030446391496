<script>
/**
 * Top-users component
 */
export default {
  props: ["pendingClaim"],
  data() {
    return {};
  },
  mounted() { },
  methods: {
    getSafe(fn, defaultValue = "N/A") {
      try {
        return fn();
      } catch (error) {
        return defaultValue;
      }
    },
  },
};
</script>

<template>
  <div class="col-lg-6 col-12">
    <div class="card shadow-none border border-radius-12">
      <div class="card-body">
        <div class="float-end">
          <router-link to="/business/listing/request" class="gray-text fw-600">
            View All Requested Claims
          </router-link>
        </div>
        <!-- <div class="float-end">
          <b-dropdown
            variant="white"
            toggle-class="p-0"
            menu-class="dropdown-menu-end"
          >
            <template v-slot:button-content>
              <span class="text-muted">
                All Members
                <i class="mdi mdi-chevron-down ms-1"></i>
              </span>
            </template>
<b-dropdown-item href="#">Locations</b-dropdown-item>
<b-dropdown-item href="#">Revenue</b-dropdown-item>
<b-dropdown-item href="#">Join Date</b-dropdown-item>
</b-dropdown>
</div> -->
        <h4 class="card-title mb-4">Requested Claims</h4>

        <div data-simplebar style="max-height: 336px">
          <div class="table-responsive">
            <table class="table table-borderless table-centered table-nowrap gray-text">
              <tr>
                <th>No.</th>
                <th>Owner Name</th>
                <th>Business Name</th>
                <th>Contact Number</th>
                <th>Website</th>
                <th>Request On</th>
              </tr>
              <tbody>
                <tr v-if="!pendingClaim">
                  <td class="text-center" colspan="6">Record Not Found</td>
                </tr>
                <tr v-else v-for="(item, index) in pendingClaim" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ getSafe(() => item.user.full_name, "N/A") }}</td>
                  <td>{{ getSafe(() => item.vendorlisting.title, "N/A") }}</td>
                  <td>{{ getSafe(() => item.contact_number, "N/A") }}</td>
                  <td>{{ getSafe(() => item.website, "N/A") }}</td>
                  <td>{{ getSafe(() => item.created_at, "N/A") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- enbd table-responsive-->
        </div>
        <!-- data-sidebar-->
      </div>
      <!-- end card-body-->
    </div>
    <!-- end card-->
  </div>
  <!-- end col -->
</template>
