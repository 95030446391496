<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />
    <Stat :stats="counter" :totalClaims="totalClaims" :acceptedCount="acceptedClaimsCount" :rejectedClaimsCount="rejectedClaimsCount" :sponsoredBusines="sponsoredBusines" :payments="payments"/>
    <div class="row">
      <topUsers :users="allUsers" />
      <activeBusiness :businesses="allBusiness" />
      <pendingClaim :pendingClaim="pendingClaims" />
      <acceptedClaim :acceptedClaim="acceptedClaims" />
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";

import Stat from "@/components/widgets/stat";
import topUsers from "@/views/pages/dashboard/top-users.vue";
import activeBusiness from "../dashboard/active-business.vue";
import pendingClaim from "../dashboard/pending-claim.vue";
import acceptedClaim from "../dashboard/accepted-claim.vue";

export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Stat,
    topUsers,
    activeBusiness,
    pendingClaim,
    acceptedClaim,
  },
  data() {
    return {
      allUsers: null,
      allBusiness: null,
      pendingClaims: null,
      acceptedClaims: null,
      title: "Dashboard",
      counter: {},
      totalClaims:null,
      acceptedClaimsCount:null,
      rejectedClaimsCount:null,
      sponsoredBusines:null,
      payments:null,
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.allCounter();
    this.allRecords();
  },
  methods: {
    allCounter() {
      this.$axios
        .get("dashboard-stats")
        .then((response) => {
          this.counter = response.data.data;
          this.totalClaims = response.data.data.claims.total
          this.acceptedClaimsCount = response.data.data.claims.accepted
          this.rejectedClaimsCount = response.data.data.claims.rejected
          this.sponsoredBusines = response.data.data.sponsoredBusiness
          this.payments = response.data.data.payments
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    allRecords() {
      this.$axios
        .get("dashboard-listing")
        .then((response) => {
          // console.log(response.data.data);
          this.allUsers = response.data.data.user;
          this.allBusiness = response.data.data.activeBusiness;
          this.pendingClaims = response.data.data.claim.pending;
          this.acceptedClaims = response.data.data.claim.accepted;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>